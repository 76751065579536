'use client';

import React from 'react';
import { Heading } from '@gravity/heading';
import { FileListAltOutline } from '@gravity/icons';
import { Text } from '@gravity/text';
import { CircleHelp, CircleUserRound, FileText, LogOut, Menu as MenuIcon, MoveRight } from 'lucide-react';
import type { HasContractsToApproveData } from '@monorepo/interfaces';
import { useLogout } from '@monorepo/api/utils/auth/useLogout';
import { usePostEvents } from '@monorepo/events';
import { EventName, EventScope } from '@monorepo/events/src/types';
import { useRouter } from '@monorepo/router';
import { UnleashFlags, useUnleashFlag } from '@monorepo/utils/hooks/unleash';
import { useImpersonateStore } from '@monorepo/utils/stores';
import { toTitleCase } from '@monorepo/utils/toTitleCase';
import { AppBar } from '@rootTailwindUi/src/components/AppBar';
import { Link } from '@srcTailwindUi/components/Link';
import { Sheet, SheetClose, SheetContent, SheetTrigger } from '@srcTailwindUi/components/Sheet';
import FIDCAlert from './FIDCAlert';
export const Menu = ({
  impersonateEnabled,
  userName,
  fidcData
}: {
  impersonateEnabled?: boolean;
  userName?: string;
  fidcData?: HasContractsToApproveData;
}) => {
  const {
    mutate: logout
  } = useLogout();
  const {
    mutate: postEvents
  } = usePostEvents();
  const {
    push
  } = useRouter();
  const {
    setIsOpenImpersonateDialog
  } = useImpersonateStore();
  const isIRPageEnabled = useUnleashFlag(UnleashFlags.REG_561_ENABLE_IR_STATEMENTS_PAGE);
  const handleImpersonateClick = () => {
    setIsOpenImpersonateDialog(true);
  };
  const handleIRClick = () => {
    postEvents({
      app: 'negotiation',
      event: EventName.BUTTON_CLICKED,
      scope: EventScope.IMPOSTO_RENDA,
      data: {
        button_name: 'Demonstrativos de IR'
      }
    });
    push('/negociacao/demonstrativo-imposto-renda', {
      useWindowOpen: true
    });
  };
  const handleLogout = () => {
    postEvents({
      app: 'meu-isaac',
      event: 'user_logout'
    });
    logout();
  };
  const handleClick = () => {
    postEvents({
      app: 'meu-isaac',
      event: 'click_menu_portal'
    });
  };
  return <Sheet data-sentry-element="Sheet" data-sentry-component="Menu" data-sentry-source-file="index.tsx">
      <SheetTrigger asChild data-sentry-element="SheetTrigger" data-sentry-source-file="index.tsx">
        <button className="group relative flex items-center justify-center p-0 hover:before:-z-[1]" onClick={handleClick}>
          <MenuIcon className="relative bg-blue-600 text-white group-hover:-z-[2]" data-sentry-element="MenuIcon" data-sentry-source-file="index.tsx" />
        </button>
      </SheetTrigger>
      <SheetContent side="right" className="flex w-full flex-col bg-white sm:w-[350px]" data-sentry-element="SheetContent" data-sentry-source-file="index.tsx">
        <div className="md:px-6 md:pt-6">
          <AppBar title="Menu" insideSheet className="relative" data-sentry-element="AppBar" data-sentry-source-file="index.tsx" />
        </div>

        <div className="mr-8 flex flex-col gap-2 p-6">
          <Heading variant="heading-h4-medium" className="text-gray-800" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
            {toTitleCase(userName)}
          </Heading>
          <Text variant="body-2-regular" className="text-gray-600" data-sentry-element="Text" data-sentry-source-file="index.tsx">
            Responsável financeiro
          </Text>
        </div>
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col">
            <div className="border-b border-solid border-b-gray-50" />
            <FIDCAlert fidcData={fidcData} data-sentry-element="FIDCAlert" data-sentry-source-file="index.tsx" />

            {isIRPageEnabled && <>
                <div className="px-6">
                  <SheetClose asChild>
                    <button className="flex w-full justify-between py-6" onClick={handleIRClick}>
                      <div className="flex items-center gap-1">
                        <FileListAltOutline className="mr-1 text-gray-800" />
                        <Text variant="body-1-medium" className="text-gray-800">
                          Demonstrativos de IR
                        </Text>
                      </div>
                      <MoveRight className="text-gray-800" />
                    </button>
                  </SheetClose>
                </div>

                <div className="border-b border-solid border-b-gray-50" />
              </>}

            <div className="px-6">
              <SheetClose asChild data-sentry-element="SheetClose" data-sentry-source-file="index.tsx">
                <Link href="https://meuisaac.zendesk.com/hc/pt-br" target="_blank" className="flex justify-between py-6 no-underline" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                  <div className="flex items-center gap-1">
                    <CircleHelp className="mr-1 text-gray-800" data-sentry-element="CircleHelp" data-sentry-source-file="index.tsx" />
                    <Text variant="body-1-medium" className="text-gray-800" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                      Dúvidas frequentes
                    </Text>
                  </div>
                  <MoveRight className="text-gray-800" data-sentry-element="MoveRight" data-sentry-source-file="index.tsx" />
                </Link>
              </SheetClose>
            </div>

            <div className="border-b border-solid border-b-gray-50" />

            <div className="px-6">
              <Link href={'https://drive.google.com/file/d/1vh7dzZXfoQyRd0W0x8oeSCJstYZabvKE/view?usp=sharing'} className="flex justify-between py-6 no-underline" target="_blank" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <div className="flex items-center gap-1">
                  <FileText className="mr-1 text-gray-800" data-sentry-element="FileText" data-sentry-source-file="index.tsx" />
                  <Text variant="body-1-medium" className="text-gray-800" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                    Termos e condições de uso
                  </Text>
                </div>
                <MoveRight className="text-gray-800" data-sentry-element="MoveRight" data-sentry-source-file="index.tsx" />
              </Link>
            </div>

            {impersonateEnabled ? <>
                <div className="border-b border-solid border-b-gray-50" />

                <div className="w-full px-6">
                  <SheetClose asChild>
                    <button className="flex w-full justify-between py-6" onClick={handleImpersonateClick}>
                      <div className="flex items-center gap-1">
                        <CircleUserRound className="mr-1 text-gray-800" />
                        <Text variant="body-1-medium" className="text-gray-800">
                          Impersonate
                        </Text>
                      </div>
                      <MoveRight className="text-gray-800" />
                    </button>
                  </SheetClose>
                </div>
              </> : null}

            <div className="border-b border-solid border-b-gray-50" />
          </div>

          <div className="flex flex-col">
            <div className="border-b border-solid border-b-gray-50" />

            <button className="flex justify-between p-6" onClick={() => handleLogout()}>
              <div className="flex items-center gap-1">
                <LogOut className="mr-1 text-gray-800" data-sentry-element="LogOut" data-sentry-source-file="index.tsx" />
                <Text variant="body-1-medium" className="text-gray-800" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                  Sair
                </Text>
              </div>
              <MoveRight className="text-gray-800" data-sentry-element="MoveRight" data-sentry-source-file="index.tsx" />
            </button>
          </div>
        </div>
      </SheetContent>
    </Sheet>;
};